import { documentationLevels } from '@api';

import { apiUtils } from '../api-utils';


export function getScenarios(): Promise<Scenario[]> {
    return apiUtils.get('/products/ppm/scenario');
}

export function createScenario(scenario: Scenario): Promise<Scenario> {
    return apiUtils.post('/products/ppm/scenario', scenario);
}

export function updateScenario(scenario: Scenario): Promise<Scenario> {
    return apiUtils.put('/products/ppm/scenario', scenario);
}

export function testScenario(scenario: Scenario): Promise<ScenarioResult> {
    return apiUtils.post('/products/ppm/scenario/result', scenario);
}

export async function deleteScenario(scenarioID: string): Promise<void> {
    await apiUtils.delete(`/products/ppm/scenario?id=${scenarioID}`);
}

export interface Scenario {
    id: string;
    name: string;
    request: EligibleRateRequest;
    targetPrice: TargetPrice;
    lastAccessedDate: string | null;
    createdDate: string;
}

export interface TargetPrice {
    minParRate: number;
    maxParRate?: number;
}

export interface EligibleRateRequest {
    requestAction: string;
    eppsUserName: string;
    requemloestAction?: string;
    lockDays: number[];
    loanType: string;
    productType: number[];
    specialProducts: number[];
    standardProducts: number[];
    documentationLevel: keyof typeof documentationLevels;
    deliveryType?: number;
    productOptions?: number[];

    borrowers: {
        firstName: string;
        lastName: string;
        phone: string;
        streetAddress: string;
        city: string;
        state: string;
        zip: string;
        email: string;

        // Typo in financial is intentional...Ellie returns it that way
        borrowerFinanancial: {
            creditScore: number;
            liquidAsset: number;
            retirementAsset: number;
            income: number;
            monthlyDebt: number;
        }
    }[];

    borrowerFinancialHistory: {
        '30DaysMortgageLatestin12Months': 0;
        '60DaysMortgageLatestin12Months': 0;
        '90DaysMortgageLatestin12Months': 0;
        '30DaysMortgageLatestin24Months': 0;
        '60DaysMortgageLatestin24Months': 0;
        '90DaysMortgageLatestin24Months': 0;
        '120DaysMortgageLatestin12Months': 0;
        noticeOfDefaultForeClosure: number;
        bankruptcyInMonths: null;
        demonstrateHousingPaymentHistory: boolean;
        firstTimeHomeBuyers: boolean;
    } | {};

    loanInformation: {
        lienPosition: number;
        prepayPenaltyTerms: number;
        loanPurpose: number;
        firstMortgageAmount: number;
        secondMortgageAmount: number;
        totalMortgageAmount: number;
        financedAmount: number;
        otherPayment: number;
        cashOut: number;
        target: number;
        includeClosingCost: boolean;
        noClosingCost: boolean;
        loanChannel: number;
        noLoan: boolean;
        loanId: string;
        giftForDownPayment: number;
        sellerConsessionForDownPayment: number;
        helocInitialDraw: number;
        helocCreditLimit: number;
        totSubClosedEndMort: number;
        helocDrawnTotal: number;
        loanAmountMaxLine: number;
    };

    compensation: {
        model: number;
        points: number;
        pointsTotalBased: string;
        amount: number;
        minimum: number;
        maximum: number
    };

    property: {
        value: number;
        type: number;
        use: number;
        zip: string;
        streetAddress: string;
        city: string;
        county: string;
        state: string;
        rural: boolean;
        tax: number;
        insuranceAmount: number;
        associationFee: number;
        rentalIncome: number;
        occupancyRate: number;
    };
}

export interface ScenarioResult {
    loanId: number;
    loanPrograms: LoanProgram[] | null;
}

export interface LoanProgram {
    va: boolean;
    fha: boolean;
    ltv: number;
    ppp: number;
    apor: number | null;
    term: number;
    cltv: number;
    usda: boolean;
    Notes: string;
    hcltv: number;
    margin: null;
    rateID: number;
    deleted: number;
    options: string;
    program: string;
    firstCap: null;
    holdback: number;
    lenderID: number;
    lockDays: number;
    rateDate: string | null;
    userComp: number;
    adjPeriod: null;
    indexName: string;
    introRate: null;
    programID: number;
    rateStale: number;
    wholesale: number;
    branchComp: number;
    docLowered: number;
    firstFloor: null;
    flagFilter: number;
    indexValue: null;
    nonOptions: string;
    opeCategory: number | null;
    introPeriod: null;
    lifetimeCap: null;
    periodicCap: null;
    programCode: string;
    rateDetails: RateDetail[] | null;
    filteredRates: RateDetail[] | null;
    documentation: string;
    dpeThreshold1: number | null;
    dpeThreshold2: number | null;
    drawPeriodmos: null;
    lifetimeFloor: null;
    productTypeID: number;
    programTypeID: number;
    rateDocMaxAge: number | null;
    customLenderID: null;
    firstAdjPeriod: number;
    locksAvailable: string;
    documentationID: number;
    ignoreLoanLimit: number;
    startingAdjRate: number | null;
    programVersionID: number;
    startingAdjPrice: number | null;
    undiscountedRate: number | null;
    libraryDocSetGuid: string;
    rateDocInstanceID: number | null;
    marginRoundingCode: string;
    repaymentPeriodmos: null;
    activeGuidelineLink: number;
    marginRoundingFactor: string;
}

export interface RateDetail {
    pnI: number;
    comp: number;
    rate: number;
    price: number;
    bRatio: number;
    margin: number;
    compVal: number;
    holdback: number;
    priceVal: number;
    rateDataID: number;
    bonaFideDiscountPointsTest: boolean | null;
    bonaFideDiscountPointsFactor: number;
}
